import React, { memo } from "react";
import { styled } from "goober";

import AugmentTooltip from "@/game-tft/AugmentTooltip.jsx";
import StaticTFT from "@/game-tft/static.mjs";

const AugmentsList = styled("div")`
  display: flex;
  align-items: center;
`;

const MatchAugmentList = ({ augments, set, size = 26 }) => {
  const [hasError, setHasError] = React.useState(false);
  if (!augments?.length) return "";

  return (
    <>
      <AugmentsList>
        {augments.map((augment) => {
          return (
            <AugmentTooltip augment={augment} key={augment}>
              <img
                src={StaticTFT.getAugmentImage(augment, set)}
                alt={augment}
                width={size}
                height={size}
                onError={(e) => {
                  setHasError(true);
                  e.target.style.display = "none";
                }}
              />
            </AugmentTooltip>
          );
        })}
      </AugmentsList>
      {!hasError ? <div className="gap-dot" /> : ""}
    </>
  );
};

export default memo(MatchAugmentList);
