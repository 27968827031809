// This is used to represent a group of units
// when presented from a match (mathtile, postmatch, etc.)
import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { mobile } from "clutch/src/Style/style.mjs";

import { Star, Stars } from "@/game-tft/CommonComponents.jsx";
import { CHAMP_BLACKLIST } from "@/game-tft/constants.mjs";
import getItemByItemSet from "@/game-tft/get-item-by-item-set.mjs";
import MatchTileApiUnitListMatch from "@/game-tft/MatchTileUnitListMatch.jsx";
import StaticTFT from "@/game-tft/static.mjs";
import orderArrayBy from "@/util/order-array-by.mjs";

const Champion = styled("div")`
  position: relative;
  margin-right: var(--sp-1_5);
  margin-top: var(--sp-3);

  &:last-of-type {
    margin-right: 0;
  }

  .chosenIcon {
    position: absolute;
    z-index: 2;
    right: calc(-1 * var(--sp-0_5));
    top: -5px;
    width: 0.825rem;
  }
`;
const ChampionsList = styled("div")`
  display: flex;
  justify-content: flex-end;

  ${mobile} {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
`;
const ChampionEmpty = styled("div")`
  box-sizing: border-box;
  border-radius: var(--br-sm);
  border: var(--sp-px) solid var(--shade7);
  background: var(--shade8);
  box-shadow: inset 0 0 0 var(--sp-px) var(--shade9);
  ${({ $size }) =>
    $size &&
    `height: ${$size}px;
    width: ${$size}px;
  `};
`;
const ToolTipName = styled("div")`
  display: flex;
  align-items: center;

  [class="type-body1"] {
    margin-right: var(--sp-1);
  }

  ${Star} {
    height: var(--sp-4);
    width: var(--sp-4);
  }
`;
const ToolTipItem = styled("div")`
  display: flex;
  align-items: center;

  [class="type-caption"] {
    color: var(--shade2);
  }

  img {
    width: var(--sp-6);
    height: auto;
    margin-right: var(--sp-2);
    margin-bottom: var(--sp-1);
    border-radius: var(--br);
  }
`;

const MatchTileApiUnitList = ({
  champions,
  units,
  unitSize,
  set,
  isSkinsEnabled,
  itemsBySet,
}) => {
  const { t } = useTranslation();

  units = units?.filter(
    (unit) => !CHAMP_BLACKLIST(set).includes(unit?.character_id),
  );
  const unitsLength = units?.length;
  const neededEmptyPieces = unitsLength < 9 ? 9 - unitsLength : 0;
  return (
    <ChampionsList>
      {orderArrayBy(units, "rarity", "asc")?.map((unit, i) => {
        const { character_id: characterId, tier, itemNames: itemKeys } = unit;
        const unitName = characterId;

        const champion = champions?.[unitName] || {
          key: unitName,
          apiKey: unitName,
          name: unitName,
        };

        const isValidTier = /\b[2-4]\b/.test(tier);

        const pieceTooltip = (
          <>
            <ToolTipName>
              <div className="type-body1">{champion.name}</div>
              {isValidTier && <Stars tier={tier} />}
            </ToolTipName>
            {itemKeys?.map((itemKey, i) => {
              const unitItem = getItemByItemSet(
                { key: "apiKey", value: itemKey },
                itemsBySet,
              );
              if (unitItem) {
                return (
                  <ToolTipItem key={`${itemKey}_${i}`}>
                    <img
                      src={StaticTFT.getItemImage(itemKey, set)}
                      alt={unitItem.name}
                    />
                    <div className="type-caption">{unitItem.name}</div>
                  </ToolTipItem>
                );
              }
              return (
                <ToolTipItem key={`unknownItem_${i}`}>
                  <img src="" alt="fallback" />
                  <div className="type-caption">
                    {t("tft:common.unknownItem", "Unknown Item")}
                  </div>
                </ToolTipItem>
              );
            })}
          </>
        );

        return (
          <MatchTileApiUnitListMatch
            key={`${unitName}_${i}`}
            champion={champion}
            unitSize={unitSize}
            itemsBySet={itemsBySet}
            itemKeys={itemKeys}
            tooltip={pieceTooltip}
            tier={isValidTier ? tier : 0}
            set={set}
            isSkinsEnabled={isSkinsEnabled}
          />
        );
      })}
      {new Array(neededEmptyPieces).fill(undefined).map((a, key) => (
        <Champion key={key}>
          <ChampionEmpty $size={unitSize} />
        </Champion>
      ))}
    </ChampionsList>
  );
};

export default MatchTileApiUnitList;
