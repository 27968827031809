import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { css, styled } from "goober";
import { Select } from "clutch/src/Select/Select.jsx";
import { mobile, mobileSmall } from "clutch/src/Style/style.mjs";

import { readState } from "@/__main__/app-state.mjs";
import appRefs from "@/app/refs.mjs";
import {
  CHAMP_BLACKLIST,
  companionDirectoryReplace,
  QUEUE_SYMBOLS,
  QUEUE_SYMBOLS_TO_QUEUE_IDS,
  titleColor,
  UNBUYABLE_CHAMPS,
} from "@/game-tft/constants.mjs";
import MatchAugmentList from "@/game-tft/MatchTileAugmentList.jsx";
import MatchTileTraitList from "@/game-tft/MatchTileTraitList.jsx";
import MatchTileUnitList from "@/game-tft/MatchTileUnitList.jsx";
import MultiLine from "@/game-tft/MultiLineChart.jsx";
import { TFTScoreAvatar } from "@/game-tft/PostMatchScoreboardScore.style.jsx";
import StaticTFT from "@/game-tft/static.mjs";
import ShareIcon from "@/inline-assets/share.svg";
import { TimeAgo } from "@/shared/Time.jsx";
import { useGameSymbol } from "@/util/game-route.mjs";
import globals from "@/util/global-whitelist.mjs";
import { getLocaleString } from "@/util/i18n-helper.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { sendInteractionEvent } from "@/util/use-interaction-event.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const Units = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
`;
const TraitsList = styled("div")`
  display: flex;
`;
const championSize = 38;

const Container = styled("div")`
  display: flex;
  gap: var(--sp-4);
  padding: var(--sp-4);
  position: relative;

  .profile_match-image {
    flex-shrink: 0;
    position: relative;
    width: var(--sp-14);
    height: var(--sp-14);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: var(--shade10);
    border-radius: var(--br);
    z-index: 1;

    @media screen and (max-width: 650px) {
      margin: 0;
      width: var(--sp-12);
      height: var(--sp-22);
    }

    @media screen and (max-width: 410px) {
      height: calc(var(--sp-1) * 32);
    }
  }

  .profile_match-icon {
    box-sizing: border-box;
    position: absolute;
    display: flex;
    right: calc(var(--sp-3) * -1);
    top: var(--sp-3);
    align-items: center;
    justify-content: center;
    width: var(--sp-6);
    height: var(--sp-6);
    max-width: 100%;
    padding: 3px;
    background-color: var(--shade8);
    border-radius: var(--br-sm);

    svg {
      display: block;
      height: auto;
    }
  }

  .gap-dot {
    height: var(--sp-1);
    width: var(--sp-1);
    margin: 0 var(--sp-2);
    background: var(--shade3);
    border-radius: 50%;
  }

  .profile_match-stats {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    gap: var(--sp-3);
    flex: 1;

    ${mobile} {
      flex-wrap: wrap;

      .match-title-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: var(--sp-2);
      }
    }

    @media screen and (max-width: 410px) {
      .match-title-wrapper {
        padding-bottom: var(--sp-4);
      }
    }

    ${mobileSmall} {
      padding-right: var(--sp-2);
    }
  }

  .match-sub-stat {
    color: var(--shade2);
    white-space: nowrap;
  }

  .lolmatch-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @media screen and (max-width: 650px) {
      margin-bottom: var(--sp-1);
    }
  }
  .lolmatch-vision,
  .lolmatch-dmg {
    @media screen and (max-width: 550px) {
      display: none;
    }
  }
  .valmatch-1 {
    flex: 1;
  }
  .valmatch-2 {
    flex: 1.5;
  }
`;

const textSelect = () => css`
  button.selectButton {
    border: none !important;
    background: transparent !important;
    padding: 0;
    outline: none !important;
    padding-right: var(--sp-8);
    height: fit-content;

    span {
      color: var(--shade2);
      font-size: 0.6875rem;
      font-weight: 600;
      letter-spacing: 0.015rem;
      text-transform: uppercase;
    }

    svg.caret {
      fill: var(--shade2) !important;
    }

    &:hover {
      span {
        color: var(--shade0);
      }

      svg.caret {
        fill: var(--shade0) !important;
      }
    }
  }
`;

const showAvg = ["interestEarned"];

const ProfileMatch = ({ image, children }) => {
  return (
    <Container>
      <div
        className={[
          "profile_match-image",
          css`
            width: var(--sp-15);
            height: var(--sp-17);
            background: var(--shade8);
          `,
        ].join(" ")}
        style={{
          backgroundImage: `url(${image})`,
        }}
      />
      <div className="profile_match-stats">{children}</div>
    </Container>
  );
};

const MatchTile = ({ name, match, matchId, mathchRoute }) => {
  const [gameName, _tagLine] = name.split("-");

  const participant = match?.info.participants.find((participant) => {
    return (
      participant.BLITZ_riot_account.account.game_name.toLowerCase() ===
      gameName.toLowerCase()
    );
  });
  if (!participant) return null;

  const { companion } = participant;

  const tileImage = companionDirectoryReplace(
    StaticTFT.getCompanionIcon(companion.content_ID),
  );
  return (
    <ProfileMatch image={tileImage}>
      <Stats participant={participant} match={match} />
      <CoachingStats participant={participant} matchId={matchId} />
      <LikeAndShare mathchRoute={mathchRoute} />
    </ProfileMatch>
  );
};

const LikeAndShare = ({ mathchRoute }) => {
  const { t } = useTranslation();
  const { onMatchtileShare } = useSnapshot(appRefs);
  const gameSymbol = useGameSymbol();
  const { searchParams, currentPath } = useRoute();
  const urlWeb = globals.location.origin + mathchRoute;
  const urlCanonical = mathchRoute;

  const handleCopyLink = useCallback(
    (e) => {
      e.stopPropagation?.();
      e.preventDefault?.();
      onMatchtileShare?.(gameSymbol, {
        urlWeb,
        urlCanonical,
        route: currentPath,
        routeParams: Array.from(searchParams.values()),
      });
      globals.navigator.clipboard.writeText(urlWeb);
      sendInteractionEvent("match-tile-copy-link");
      // hide tooltip after 2 seconds
      setTimeout(() => {
        e.target.dataset.tooltip = "";
      }, 2000);
    },
    [
      currentPath,
      gameSymbol,
      onMatchtileShare,
      searchParams,
      urlCanonical,
      urlWeb,
    ],
  );

  return (
    <LikeShareContainer>
      <button
        className="share"
        onClick={handleCopyLink}
        data-tooltip={t("common:share.copied", "Web link copied to clipboard!")}
        data-event="click"
      >
        <ShareIcon />
      </button>
    </LikeShareContainer>
  );
};

const LikeShareContainer = styled("div")`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  .share {
    padding: var(--sp-1_5) var(--sp-3);
    border: 1px solid var(--shade6);
    border-radius: var(--br);
    background: transparent;
    cursor: pointer;

    &:hover {
      background: var(--shade5-50);
    }
  }
`;

const Stats = ({ participant, match }) => {
  const { t } = useTranslation();
  const {
    info: { queue_id: queueId, game_datetime: createdAt },
  } = match;
  const { units, placement } = participant;
  const { searchParams } = useRoute();
  const set = StaticTFT.getMatchSetByDate(createdAt, queueId, searchParams);
  const state = useSnapshot(readState);
  const items = state.tft[set]?.items;
  const champions = state.tft[set]?.champions;
  const isSkinsEnabled = state.settings.tft.isSkinsEnabled;

  const itemsBySet = items;

  const filteredUnits = units.filter(
    (unit) => !CHAMP_BLACKLIST(set).includes(unit.character_id),
  );

  const finalUnits =
    QUEUE_SYMBOLS_TO_QUEUE_IDS[QUEUE_SYMBOLS.tftTockersTrials] === queueId
      ? filteredUnits.filter((unit, index, self) => {
          return (
            index ===
            self.findIndex((t) => JSON.stringify(t) === JSON.stringify(unit))
          );
        })
      : filteredUnits;

  const traits = participant.traits.length
    ? participant.traits
    : StaticTFT.inferTraitsFromUnits({
        units: finalUnits.map((unit) => {
          return {
            characterId: unit.character_id,
          };
        }),
        traitsStaticData: state.tft[set]?.traits,
        unitsStaticData: champions,
        matchSet: set,
      });

  return (
    <MatchInfoContainer>
      <MatchTitleInfo>
        <p
          className={["type-article-headline", titleColor(placement)].join(" ")}
        >
          {t("common:ordinalPlace", "{{place, ordinal}} Place", {
            place: placement,
          })}
        </p>
        <MatchInfoSummary>
          <MatchAugmentList augments={participant.augments} set={set} />
          <TraitsList>
            <MatchTileTraitList traits={traits} set={set} max={4} />
          </TraitsList>
          {traits?.length ? <div className="gap-dot" /> : ""}
          <p className="type-caption match-sub-stat">
            {StaticTFT.translateQueueType(t, queueId)}
          </p>
        </MatchInfoSummary>
      </MatchTitleInfo>
      <Units>
        <MatchTileUnitList
          units={finalUnits}
          unitSize={championSize}
          set={set}
          champions={champions}
          isSkinsEnabled={isSkinsEnabled}
          itemsBySet={itemsBySet}
        />
        <p
          className={[
            "type-caption match-sub-stat",
            css`
              margin-top: auto;
              margin-left: auto;
            `,
          ].join(" ")}
        >
          <TimeAgo date={createdAt} />
        </p>
      </Units>
    </MatchInfoContainer>
  );
};

const CoachingStats = ({ participant, matchId }) => {
  const state = useSnapshot(readState);
  const match = state.tft.matches_v2[matchId];
  const matchRound = state.tft.matches_round?.[matchId];
  const matchSet = "set" + match.info.tft_set_number;
  const championStatic = state.tft[matchSet]?.champions;
  const allMatches = state.tft.matches_round;
  const { t } = useTranslation();

  const [barStat, setBarStat] = useState("boardValue");
  const [graphStat, setGraphStat] = useState("boardValue");

  const barOptions = [
    { value: "boardValue", text: [t("tft:boardValue", "Board Value")] },
    {
      value: "interestEarned",
      text: [t("tft:interestEarned", "Interest Earned")],
    },
    // { value: "damageDealt", text: [t("tft:damageDealt", "Damage Dealt")] },
    { value: "level", text: [t("tft:level", "Level")] },
  ];

  const graphOptions = [
    { value: "boardValue", text: [t("tft:boardValue", "Board Value")] },
    {
      value: "interestEarned",
      text: [t("tft:interestEarned", "Interest Earned")],
    },
    // { value: "damageDealt", text: [t("tft:damageDealt", "Damage Dealt")] },
    { value: "level", text: [t("tft:level", "Level")] },
    { value: "health", text: [t("tft:health", "Health")] },
  ];

  const barValues = useMemo(() => {
    if (!match) return;

    const matchPlayers = match?.info?.participants;

    return getComparisonBarValues({
      stat: barStat,
      matchPlayers,
      championStatic,
      participant,
      allMatches,
      matchRound,
      t,
    });
  }, [match, barStat, championStatic, participant, allMatches, matchRound, t]);

  const [graphValues, lobbyGraphValues] = useMemo(() => {
    if (!match) return [[], []];

    return getGraphValues({
      stat: graphStat,
      championStatic,
      matchRound,
      participant,
      t,
      allMatches,
    });
  }, [
    allMatches,
    championStatic,
    graphStat,
    match,
    matchRound,
    participant,
    t,
  ]);

  if (!match) return "";

  const matchPlayers = match?.info?.participants;

  return (
    <CoachingStatsContainer
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {matchPlayers?.length > 1 ? (
        <ComparisonBars
          stat={barStat}
          values={barValues}
          setStat={setBarStat}
          options={barOptions}
        />
      ) : (
        ""
      )}
      {graphValues?.length > 5 ? (
        <OverTimeGraph
          stat={graphStat}
          values={graphValues}
          values2={lobbyGraphValues}
          t={t}
          setStat={setGraphStat}
          options={graphOptions}
        />
      ) : (
        ""
      )}
    </CoachingStatsContainer>
  );
};

const CoachingStatsContainer = styled("div")`
  display: flex;
  align-items: center;
  gap: var(--sp-3);
  width: 100%;

  ${mobile} {
    align-items: stretch;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    position: absolute;
    bottom: 12%;
    left: 0;
    padding-left: var(--sp-5);
    padding-right: var(--sp-8);
    padding-top: var(--sp-4);
    padding-bottom: var(--sp-4);
    background: var(--shade8);

    > div {
      max-width: 80%;
      flex-basis: 80%;
      flex-grow: 0;
      flex-shrink: 0;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const getGraphValues = ({
  stat,
  championStatic,
  matchRound,
  participant,
  t,
  allMatches,
}) => {
  if (!matchRound || matchRound instanceof Error) return [[], []];
  const { round_data } = matchRound;
  if (!round_data) return [[], []];

  const getBoardValueFromRounds = (champions) => {
    if (!champions) return 0;
    return champions.reduce((acc, unit) => {
      if (
        !unit.board ||
        UNBUYABLE_CHAMPS.some(
          (c) =>
            StaticTFT.getChampName(c)?.toLowerCase() === unit.id?.toLowerCase(),
        )
      )
        return acc;
      const champion = Object.values(championStatic).find(
        (c) => c.name === unit.id,
      );
      const cost = champion?.cost || 0;
      const tier = unit.star;
      const copies = Math.pow(3, tier - 1);
      const totalCost = copies * cost;
      return acc + totalCost;
    }, 0);
  };

  const newGraphValues = [];
  let newLobbyGraphValues = [];

  let interestEarned = 0;

  Object.keys(round_data).forEach((stage) => {
    Object.keys(round_data[stage]).forEach((round) => {
      const roundPlayer = round_data[stage][round].players.find(
        (p) => p.game_name === participant.BLITZ_riot_account.account.game_name,
      );
      const champions = roundPlayer?.champions;
      const stageRound = stage + "-" + round;

      if (stat === "boardValue") {
        const boardValue = getBoardValueFromRounds(champions);
        newGraphValues.push({
          x: stageRound,
          y: boardValue,
          label: t("tft:roundNumber", "Round {{number}}", {
            number: stageRound,
          }),
        });
        const alivePlayers = round_data[stage][round].players?.filter(
          (p) => p.health > 0,
        );
        const lobbyBoardValues =
          alivePlayers.reduce((a, c) => {
            const champions = c?.champions;
            return a + getBoardValueFromRounds(champions);
          }, 0) / alivePlayers?.length;
        newLobbyGraphValues.push({
          x: stageRound,
          y: lobbyBoardValues,
          label: t("tft:roundNumber", "Round {{number}}", {
            number: stageRound,
          }),
        });
      }

      if (stat === "interestEarned") {
        interestEarned += roundPlayer.gold_interest;
        newGraphValues.push({
          x: stageRound,
          y: interestEarned,
          label: t("tft:roundNumber", "Round {{number}}", {
            number: stageRound,
          }),
        });
      }

      if (stat === "level") {
        newGraphValues.push({
          x: stageRound,
          y: roundPlayer.level,
          label: t("tft:roundNumber", "Round {{number}}", {
            number: stageRound,
          }),
        });

        const alivePlayers = round_data[stage][round].players?.filter(
          (p) => p.health > 0,
        );
        const lobbyLevel =
          alivePlayers.reduce((a, c) => {
            const level = c.level;
            return a + level;
          }, 0) / alivePlayers?.length;
        newLobbyGraphValues.push({
          x: stageRound,
          y: lobbyLevel,
          label: t("tft:roundNumber", "Round {{number}}", {
            number: stageRound,
          }),
        });
      }

      if (stat === "health") {
        newGraphValues.push({
          x: stageRound,
          y: roundPlayer.health,
          label: t("tft:roundNumber", "Round {{number}}", {
            number: stageRound,
          }),
        });

        const alivePlayers = round_data[stage][round].players?.filter(
          (p) => p.health > 0,
        );
        const lobbyHealth =
          alivePlayers.reduce((a, c) => {
            const health = c.health;
            return a + health;
          }, 0) / alivePlayers?.length;
        newLobbyGraphValues.push({
          x: stageRound,
          y: lobbyHealth,
          label: t("tft:roundNumber", "Round {{number}}", {
            number: stageRound,
          }),
        });
      }
    });
  });

  // player averages
  let allFilteredMatches = [];
  if (showAvg.includes(stat)) {
    allFilteredMatches = Object.entries(allMatches)
      .filter(([_, match]) => !(match instanceof Error))
      .map(([_, match]) => match);

    if (stat === "interestEarned") {
      allFilteredMatches.forEach((match, i) => {
        const round_data = match?.round_data;

        let gameInterestEarned = 0;
        Object.keys(round_data).forEach((stage) => {
          Object.keys(round_data[stage]).forEach((round) => {
            const roundPlayer = round_data[stage][round].players.find(
              (p) =>
                p.game_name ===
                participant.BLITZ_riot_account.account.game_name,
            );
            const stageRound = stage + "-" + round;
            gameInterestEarned += roundPlayer?.gold_interest;

            const curRound = newLobbyGraphValues.find(
              ({ x }) => x === stageRound,
            );
            if (!curRound) {
              newLobbyGraphValues.push({
                x: stageRound,
                y: gameInterestEarned,
                label: t("tft:roundNumber", "Round {{number}}", {
                  number: stageRound,
                }),
              });
            } else {
              curRound.y =
                (curRound.y * (i + 1) + gameInterestEarned) / (i + 2);
            }
          });
        });
      });

      newLobbyGraphValues = newLobbyGraphValues.filter(({ x }) =>
        newGraphValues.some(({ x: x2 }) => x2 === x),
      );
    }
  }

  return [newGraphValues, newLobbyGraphValues];
};

const OverTimeGraph = ({ stat, values, values2, t, setStat, options }) => {
  const [hoveredValues, setHoveredValues] = useState(null);
  const color = "rgb(169, 82, 229)";
  const color2 = "var(--shade1)";
  const numIntervalsX = 4;
  const numberOptions = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  };

  const valueOptions = numberOptions;
  const xTicks = [];
  values.map((point, i) =>
    i % numIntervalsX === 0 ? xTicks.push(point.x) : null,
  );
  if (xTicks[xTicks.length - 1] !== values[values.length - 1].x)
    xTicks.push(values[values.length - 1].x);

  const chartConfig = {
    margin: { left: 10, right: 30, top: 20, bottom: 20 },
    xAxisConf: {
      visible: true,
      ticks: xTicks,
      dots: true,
    },
    yAxisConf: {
      visible: true,
      ticks: 4,
      tickRenderer: (yVal) => {
        return getLocaleString(yVal, valueOptions);
      },
    },
  };

  return (
    <GraphContainer>
      <Select
        options={options}
        selected={stat}
        onChange={(value) => setStat(value)}
        inputStyle
        containerClassName={textSelect()}
      />
      <GraphInner>
        <Legend className={stat === "health" ? "flipped" : ""}>
          <div>
            <div
              className="type-overline flex align-center gap-1"
              style={{ color: color }}
            >
              <div className="circle" style={{ background: color }} />
              {t("common:you", "You")}
            </div>
            <div
              className="type-title--bold"
              style={{
                color: color,
              }}
            >
              {hoveredValues ? Math.round(hoveredValues?.y1) : ""}
            </div>
          </div>
          {values2?.length ? (
            <div>
              <div
                className="type-overline flex align-center gap-1"
                style={{ color: color2 }}
              >
                <div className="circle" style={{ background: color2 }} />
                {showAvg.includes(stat)
                  ? t("tft:yourAvg", "Your Avg")
                  : t("tft:lobby", "Lobby")}
              </div>
              <div
                className="type-title--bold"
                style={{
                  color: color2,
                }}
              >
                {hoveredValues ? Math.round(hoveredValues?.y2) : ""}
              </div>
            </div>
          ) : (
            ""
          )}
        </Legend>
        <MultiLine
          margin={chartConfig.margin}
          data={values}
          data2={values2}
          xField="x"
          yField="y"
          height={120}
          xAxisConf={chartConfig.xAxisConf}
          yAxisConf={chartConfig.yAxisConf}
          color={color}
          color2={color2}
          fill={``}
          fill2={``}
          circleRadius={5}
          showGridLines={false}
          showTooltip={false}
          valueOptions={valueOptions}
          hideCircles={true}
          yAxisPosition="right"
          onHover={(point) => setHoveredValues(point)}
          showHoverLine={true}
          hoverBoth={true}
        />
      </GraphInner>
    </GraphContainer>
  );
};

const GraphInner = styled("div")`
  position: relative;
`;

const Legend = styled("div")`
  display: flex;
  align-items: center;
  gap: var(--sp-3);
  position: absolute;
  top: 0;
  left: 0;

  &.flipped {
    top: auto;
    bottom: 30px;
    left: 8px;

    > div {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  .circle {
    width: var(--sp-1_5);
    height: var(--sp-1_5);
    border-radius: 50%;
  }
`;

const GraphContainer = styled("div")`
  flex-basis: 150%;
  width: 100%;
  padding: var(--sp-4);
  border-radius: var(--br);
  background: var(--shade7);
  border: 1px solid var(--shade3-15);
  display: flex;
  flex-direction: column;
  gap: var(--sp-1_5);

  .x-axis {
    transform: none !important;
  }

  .y-axis {
    text-anchor: start !important;
  }

  .y-axis text,
  .x-axis text {
    font-size: 12px;
    font-family: "Inter";
    font-weight: bold;
    letter-spacing: normal;
  }
`;

const getComparisonBarValues = ({
  stat,
  matchPlayers,
  championStatic,
  participant,
  allMatches,
  matchRound,
  t,
}) => {
  let matchPlayersSorted = [];
  if (stat === "boardValue") {
    const getBoardValue = (units, championStatic) => {
      return units.reduce((acc, unit) => {
        const champion = championStatic?.[unit?.character_id];
        const cost = champion?.cost || 0;
        const tier = unit.tier;
        const copies = Math.pow(3, tier - 1);
        const totalCost = copies * cost;
        return acc + totalCost;
      }, 0);
    };

    const matchPlayersWithBoardValue = matchPlayers.map((player) => {
      const boardValue = getBoardValue(player.units, championStatic);
      return {
        ...player,
        boardValue,
      };
    });
    matchPlayersSorted = matchPlayersWithBoardValue
      .sort((a, b) => b.boardValue - a.boardValue)
      .slice(0, 4);

    // add player if not in top 4
    if (
      !matchPlayersSorted.find(
        (player) =>
          participant.BLITZ_riot_account.account.game_name ===
          player.BLITZ_riot_account.account.game_name,
      )
    ) {
      matchPlayersSorted.pop();
      matchPlayersSorted.push(
        matchPlayersWithBoardValue.find(
          (p) =>
            p.BLITZ_riot_account.account.game_name ===
            participant.BLITZ_riot_account.account.game_name,
        ),
      );
    }
  }

  if (stat === "level") {
    matchPlayersSorted = [...matchPlayers]
      .sort((a, b) => b.level - a.level)
      .slice(0, 4);

    // add player if not in top 4
    if (
      !matchPlayersSorted.find(
        (player) =>
          participant.BLITZ_riot_account.account.game_name ===
          player.BLITZ_riot_account.account.game_name,
      )
    ) {
      matchPlayersSorted.pop();
      matchPlayersSorted.push(
        matchPlayers.find(
          (p) =>
            p.BLITZ_riot_account.account.game_name ===
            participant.BLITZ_riot_account.account.game_name,
        ),
      );
    }
  }

  if (stat === "interestEarned") {
    const { round_data } = matchRound;

    let interestEarned = 0;
    Object.keys(round_data).forEach((stage) => {
      Object.keys(round_data[stage]).forEach((round) => {
        const roundPlayer = round_data[stage][round].players.find(
          (p) =>
            p.game_name === participant.BLITZ_riot_account.account.game_name,
        );
        interestEarned += roundPlayer.gold_interest;
      });
    });

    // player interest earned
    matchPlayersSorted.push({
      ...matchPlayers.find(
        (p) =>
          p.BLITZ_riot_account.account.game_name ===
          participant.BLITZ_riot_account.account.game_name,
      ),
      interestEarned,
    });

    // player avg interest earned
    let allFilteredMatches = [];
    allFilteredMatches = Object.entries(allMatches)
      .filter(([_, match]) => !(match instanceof Error))
      .map(([_, match]) => match);

    const playerAvgInterestEarned =
      allFilteredMatches.reduce((acc, match) => {
        const round_data = match?.round_data;
        let gameInterestEarned = 0;
        Object.keys(round_data).forEach((stage) => {
          Object.keys(round_data[stage]).forEach((round) => {
            const roundPlayer = round_data[stage][round].players.find(
              (p) =>
                p.game_name ===
                participant.BLITZ_riot_account.account.game_name,
            );
            gameInterestEarned += roundPlayer.gold_interest;
          });
        });
        return acc + gameInterestEarned;
      }, 0) / allFilteredMatches.length;

    matchPlayersSorted.push({
      ...matchPlayers.find(
        (p) =>
          p.BLITZ_riot_account.account.game_name ===
          participant.BLITZ_riot_account.account.game_name,
      ),
      interestEarned: Math.round(playerAvgInterestEarned),
      isAvg: true,
    });
  }

  return matchPlayersSorted.map((player) => {
    const isPlayer =
      participant.BLITZ_riot_account.account.game_name ===
      player.BLITZ_riot_account.account.game_name;

    let value = 0;
    if (stat === "boardValue") value = player.boardValue;
    if (stat === "level") value = player.level;
    if (stat === "interestEarned") value = player.interestEarned;
    return {
      icon: (
        <TFTScoreAvatar
          url={companionDirectoryReplace(
            StaticTFT.getCompanionIcon(player.companion.content_ID),
          )}
          style={{
            borderRadius: "var(--br-lg)",
            width: isPlayer ? `var(--sp-8)` : `var(--sp-6)`,
            height: isPlayer ? `var(--sp-8)` : `var(--sp-6)`,
          }}
        />
      ),
      label: player.isAvg
        ? t("tft:yourAvg", "Your Avg")
        : player.BLITZ_riot_account.account.game_name,
      value: value,
      isPlayer: !player.isAvg && isPlayer,
    };
  });
};

const ComparisonBars = ({
  stat,
  values = [
    {
      icon: null,
      value: 0,
      label: null,
      isPlayer: false,
    },
  ],
  setStat,
  options,
}) => {
  const max = Math.max(...values.map((v) => v.value));
  return (
    <ComparisonBarsContainer>
      <Select
        options={options}
        selected={stat}
        onChange={(value) => setStat(value)}
        inputStyle
        containerClassName={textSelect()}
      />
      {values.map(({ icon, label, value, isPlayer }, i) => {
        return (
          <div
            className={`flex align-center ${isPlayer ? "gap-3" : "gap-5"}`}
            key={label + i}
          >
            <div>{icon}</div>
            <div className="barContainer">
              <HorizontalBar
                className={isPlayer ? "isPlayer" : ""}
                style={{
                  width: `${(value / max) * 100}%`,
                }}
              >
                <div
                  className={
                    isPlayer ? `type-subtitle--bold` : `type-caption--bold`
                  }
                >
                  {label}
                </div>
                <div
                  className={
                    isPlayer ? `type-subtitle--bold` : `type-caption--bold`
                  }
                >
                  {value}
                </div>
              </HorizontalBar>
            </div>
          </div>
        );
      })}
    </ComparisonBarsContainer>
  );
};

export default MatchTile;

const ComparisonBarsContainer = styled("div")`
  width: 100%;
  padding: var(--sp-4);
  border-radius: var(--br);
  background: var(--shade7);
  border: 1px solid var(--shade3-15);
  display: flex;
  flex-direction: column;
  gap: var(--sp-1_5);
  height: 100%;

  .barContainer {
    width: 100%;
  }
`;

const HorizontalBar = styled("div")`
  background: linear-gradient(90deg, #282a31 0%, #32353c 100%);
  border-radius: var(--br);
  height: var(--sp-6);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--sp-2);
  padding: var(--sp-0_5) var(--sp-2);
  color: var(--shade0);
  box-shadow: 0px 1px 0px 0px rgba(227, 229, 234, 0.05);
  text-overflow: ellipsis;
  text-wrap: nowrap;

  &.isPlayer {
    background: linear-gradient(90deg, #373222 0%, #504623 100%);
    height: var(--sp-8);
    color: var(--yellow);
  }
`;

const MatchInfoContainer = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${mobile} {
    margin-bottom: 220px;
  }
`;

const MatchTitleInfo = styled("div")`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: var(--sp-4);
`;

const MatchInfoSummary = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
